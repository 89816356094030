@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --primaryColor: #f2ae5f;
  --whiteColor: #ffffff;
  --blackColor: #000;
  --journeyColor: #f9f6f3;
  --textColor: #112a4f;
  --healthColor: #e6dfd8;
  --syndrome: #e6dfd8;
}

body {
  margin: 0;
  font-family: 'Playfair Display', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
}


li{
  list-style-type: none;
  color: var(--blackColor);
  text-decoration: none;
}

button{
  background: none;
  border: 1px solid var(--primaryColor);
  padding: 1rem 2rem;
  cursor: pointer;
  transition: .5s;
  color: var(--textColor);
}

button:hover{
  color: var(--whiteColor);
  background-color: var(--primaryColor);
  border: none;
}

h1{
  font-size: clamp(1rem, 5vw, 6rem);
}

h2{
  font-size: clamp(1rem, 5vw, 3.5rem);
  color: var(--textColor);
}

p{
  font-size: clamp(.7rem, 5vw, 1.7rem);
}



