.heading3{
    width: 100%;
    height: 65vh;
}

.heading3-content{
    margin: 5rem 18.3rem;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    overflow: hidden;
}

.heading3-left{
    max-width: 100%;
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
}

.heading3-text{
    display: flex;
    align-items: center;
    gap: 1.1rem;
    margin-left: 50%;
}

.heading3-divider{
    border: 1px solid var(--primaryColor);
    height: 2.5rem;
}

.heading3-text h2{
    font-family: 'Poppins';
    color: var(--textColor);
}

.heading3-p{
    margin-top: 3rem;
}

.heading3-p>:nth-child(2){
    margin-top: 2rem;
}

.heading3-p p{
    font-size: 1.2rem;
}

.heading3-right{
    margin-top: 4rem;
}

.heading3-right img{
    max-width: 30rem;
    height: 40rem;
}

@media screen and (max-width: 1440px) {

    .heading3-right img{
        max-width: 30rem;
        height: 31rem;
        position: absolute;
        right: 10%;
    }

    .heading3-text{
        width: 100%;
        margin-left: 12.6rem;
    }

    .heading3-p{
        position: absolute;
        margin-left: -8rem;
        margin-top: 8rem;
        font-weight: 300;
    }

    .heading3-p p {
        font-size: 1.1rem;
    }
}


@media screen and (max-width: 856px) {
    .heading3-right img{
        max-width: 20rem;
        height: 20rem;
    }

    .heading3-text{
        margin-left: 0rem;
    }

    .heading3-divider{
        height: 2rem;
    }

    .heading3-content{
        margin: 0 5rem;
    }

    .heading3-p{
        margin-left: 0rem;
        margin-top: 17rem;
    }

    .heading3-p p {
        font-size: .8rem;
    }
}


@media screen and (max-width: 640px) {

    .heading3{
        width: 100%;
        height: 55vh;
    }
    
    .heading3-right img{
        max-width: 15rem;
        height: 15rem;
        margin-right: -1rem;
    }

    .heading3-p{
        margin-left: 0rem;
        margin-top: 12rem;
    }

    .heading3-text h2{
        font-size: 1.5rem;
    }

    .heading3-divider{
        height: 1.3rem;
    }
}