.blogheading1{
    width: 100%;
    height: 70vh;
    background-color: var(--journeyColor);
}

.heading1-content{
    max-width: 100%;
    margin: 0 20%;
}

.heading1{
    display: flex;
    flex-direction: column;
}

.heading1-text{
    display: flex;
    align-items: center;
    gap: 1.1rem;
}

.heading1-divider{
    border: 1px solid var(--primaryColor);
    height: 2.5rem;
}

.heading1-text h2{
    font-family: 'Poppins';
    color: var(--textColor);
}

.heading1-p{
    margin-top: 7rem;
    
}

.heading1-p p{
    font-size: 1.4rem;
    line-height: 2.5rem;
}

.heading1-p>:nth-child(2){
    margin-top: 3rem;
}

@media screen and (max-width: 1440px) {
    .blogheading1{
        height: 80vh;
    }

    .heading1-p p{
        font-size: 1.2rem;
        line-height: 1.7rem;
        font-weight: 300;
    }
}

@media screen and (max-width: 856px) {
    .heading1-content{
        margin: 0 5rem;
    }

    .heading1-divider{
        height: 2rem;
    }

    .heading1-p p{
        font-size: .8rem;
    }

    .blogheading1{
        height: 60vh;
    }
}


@media screen and (max-width: 640px) {

    .blogheading1{
        height: 55vh;
    }

    .heading1-text h2{
        font-size: 1.5rem;
    }

    .heading1-divider{
        height: 1.3rem;
    }

    .heading1-p{
        margin-top: 4rem;        
    }
}