.specialist{
    background: url('../../assets/specialist-author.png') no-repeat center/cover;
    width: 100%;
    height: 150vh;

}

.specialist-content{
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.specialist-top{
    margin: 8rem 0;
}

.specialist-divider{
    border: 1px solid var(--primaryColor);
    width: 10rem;
    display: block;
    margin: 0 35%;
    margin-bottom: 5rem;
}

.specialist-top h1{
    font-family: 'Poppins';
    color: var(--whiteColor);
}

.specialist-top>:nth-child(3){
    color: var(--primaryColor);
    font-style: italic;
}

.specialist-top p{
    margin-top: 2rem;
    color: var(--whiteColor);
    font-size: 1.5rem;
    font-weight: 300;
}

.specialist-below{
    display: flex;
    justify-content: space-evenly;
    gap: 10%;   
    width: 100%;
}

.disorder img{
    width: 8rem;
    height: 8rem;
}

.disorder{
    position: relative;
}

.disorder>:nth-child(3){
    margin-top: 5rem;
}

.disorder-divider{
    border: 1px solid var(--primaryColor);
    width: 35%;
    position: absolute;
    top: 70%;
    right: 32%;
}

.disorder h3{
    font-size: 3rem;
    color: var(--whiteColor);
    font-weight: 400;
    font-weight: 300;
}

.neuromuscular{
    position: relative;
}

.neuromuscular img{
    width: 8rem;
    height: 8rem;
}

.neuromuscular-divider{
    border: 1px solid var(--primaryColor);
    width: 25%;
    position: absolute;
    top: 70%;
    right: 37%;
}

.neuromuscular>:nth-child(3){
    margin-top: 5rem;
}

.neuromuscular h3{
    font-size: 3rem;
    color: var(--whiteColor);
    font-weight: 400;
    font-weight: 300;
}

.specialist-end{
    position: relative;
    width: 100%;
}

.specialist-end h3{
    position: absolute;
    left: 30%;
    right: 30%;
    margin-top: 20rem;
    color: var(--whiteColor);
    font-weight: 300;
    letter-spacing: 1px;
}

/* media queries */

@media screen and (max-width: 1440px) {
    .specialist{
        height: 135vh;
    }

    .specialist-top p{
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 1px;
    }

    .specialist-divider{
        margin-bottom: 2rem;
    }
    
    .disorder img{
        width: 6rem;
        height: 6rem;
    }

    .disorder-divider{
        width: 35%;
        left: 30%;
        top: 60%;
    }

    .disorder h3{
        font-size: 2rem;
        font-weight: 300;
        letter-spacing: 1px;
    }

    .neuromuscular img{
        width: 6rem;
        height: 6rem;
    }

    .neuromuscular-divider{
        width: 25%;
        right: 38%;
        top: 60%;
    }

    .neuromuscular h3{
        font-size: 2rem;
        font-weight: 300;
        letter-spacing: 1px;
    }

    .specialist-end{
        margin-top: -9rem;
    }

    .specialist-end h3{
        font-size: 1rem;
        font-weight: 300;
    }
}

/* tablet */

@media screen and (max-width: 856px){

    .specialist{
        height: 100vh;
    }
    .specialist-top{
        margin: 4rem 0;
    }

    .specialist-top p{
        font-size: 1rem;
    }

    .specialist-divider{
        margin-bottom: 2.5rem;
    }

    .disorder h3{
        font-size: 1.3rem;
    }

    .disorder img{
        width: 4rem;
        height: 4rem;
    }

    .neuromuscular h3{
        font-size: 1.3rem;
    }

    .neuromuscular img{
        width: 4rem;
        height: 4rem;
    }

    .specialist-below{
        margin-top: 2rem;
    }

    .specialist-end h3{
        font-size: 1rem;
    }
}

@media screen and (max-width: 640px) {

    .disorder img{
        width: 3rem;
        height: 3rem;
    }

    .disorder h3{
        font-size: 1rem;
    }

    .neuromuscular img{
        width: 3rem;
        height: 3rem;
    }

    .neuromuscular h3{
        font-size: 1rem;
    }

    .specialist-end h3{
        font-size: .7rem;
    }

    .specialist-top p{
        font-size: .7rem;
    }

    .specialist-divider{
        width: 7rem;
        margin: 0 35%;
        margin-bottom: 2rem;
    }
    
}