.about{
    width: 100%;
    height: 105vh;
    justify-content: space-between;
    display: flex;
}

.about-left{
    padding: 7rem 17rem;
    width: 50%;
}

.about-about{
    display: flex;
    align-items: center;
    text-align: center;
}

.about-divider{
    border: 1px solid var(--primaryColor);
    width: 1px;
    height: 2.5rem;
}

.about-about h2{
    
    margin-left: 1.1rem;
    color: var(--textColor);
}

.about-img{
    margin: 5rem 0;
}

.about-img>:nth-child(1){
    position: absolute;
    width: 19rem;
    height: 22rem;
    border: 2px solid var(--primaryColor);
    left: 18rem;
    top: 76rem;
}

.about-img img{
    position: absolute;
    max-width: 19rem;
    height: 22rem;
    position: absolute;
    object-fit: cover;
}

/* right section */

.about-right{
    width: 50%;
    margin: 5rem 0;
}

.a-right{
    margin-left: 0rem;
    margin-top: 11.5rem;
}

.a-right-divider{
    border: 1px solid var(--primaryColor);
    width: 12%;
    margin: 2rem 0;
}

.a-right h2{
    color: var(--textColor);
}

.a-right>:nth-child(3){
    color: var(--primaryColor);
    font-style: italic;
}

.a-right>:nth-child(4){
    font-size: 1rem;
}

.a-right>:nth-child(5){
    font-size: 1rem;
}

.a-right>:nth-child(6){
    font-size: 1rem;
}

.a-right p{
    margin-top: 4rem;
    max-width: 75%;
    font-weight: 300;
}

.a-img{
    position: absolute;
    margin-top: 5rem;
    right: 0;
    z-index: -1;
}

.a-img img{
    max-width: 55rem;
    max-height: 38rem; 
}

/* media queries */

@media screen and (max-width: 1440px) {

    .about-left{
        padding: 7rem 10rem;
        max-width: 50%;
    }

    .about-img img{
        max-width: 17rem;
        height: 20rem;
        object-fit: cover;
    }

    .about-img>:nth-child(1){
        width: 17rem;
        height: 20rem;
        border: 2px solid var(--primaryColor);
        top: 66.2rem;
        margin-left: -7rem;
    }

    .a-img img{
        max-width: 100%;
        max-height: 35rem;
    }

    .a-img{
        
        margin-top: 7rem;
        right: 0;
    }

    .about-right{
        max-width: 50%;
        margin: 1rem 0;
    }

    .a-right{
        margin-top: 13rem;
    }

    .a-right h2{
        font-size: 3rem;
        line-height: 2.7rem;
    }

    .a-right p{
        font-weight: 400;
        margin-top: 3rem;
    }

    .a-right>:nth-child(5),
    .a-right>:nth-child(6){
        margin-top: 1.5rem;
    }
}

/* tablet  */

@media screen and (max-width: 856px) {

    .about{
        height: 95vh;
    }
    
    .about-left{
        padding: 6rem 5rem;
    }

    .about-divider{
       height: 2rem;
    }

    .about-img>:nth-child(1){
        max-width: 17rem;
        height: 20rem;
        top: 44rem;
        margin-left: -12rem;
    }

    .about-img>:nth-child(2){
        max-width: 17rem;
        height: 20rem;
        margin-left: .2rem;
        margin-top: .9rem;
    }

    .a-img img{
        max-width: 100%;
        max-height: 20rem;
    }

    .a-img{
        margin-top: 13rem;
    }

    .a-right{
        margin-left: 1.5rem;
        margin-top: 16.5rem;
        max-width: 80%;
    }

    .a-right h2{
        font-size: 1.5rem;
    }

    .a-right p{
        margin: 2rem 0;
    }

    .a-right>:nth-child(4){
        font-size: .8rem;
    }

    .a-right>:nth-child(5){
        font-size: .8rem;
    }

    .a-right>:nth-child(6){
        font-size: .8rem;
    }
}

@media screen and (max-width: 940px) {
    .about-left{
        padding: 6rem 5rem;
    }

    .about-img>:nth-child(1){
        width: 17rem;
        height: 20rem;
        top: 64.5rem;
        margin-left: -12rem;
    }

    .a-img img{
        max-width: 100%;
        max-height: 20rem;
    }

    .a-right{
        margin-left: 4rem;
        margin-top: 10rem;
        max-width: 80%;
    }

    .a-right h2{
        font-size: 1.5rem;
    }

    .a-right p{
        margin: 2rem 0;
    }

    .a-right>:nth-child(4){
        font-size: .8rem;
    }

    .a-right>:nth-child(5){
        font-size: .8rem;
    }

    .a-right>:nth-child(6){
        font-size: .8rem;
    }

    .a-right-divider{
        margin: .5rem 0;
    }
}


@media screen and (max-width: 640px) {

    .about-left{
        padding: 4rem 2.8rem;
    }

    .about-img>:nth-child(1){
        max-width: 13.5rem;
        height: 15rem;
        top: 61rem;
        margin-left: -14.5rem;
    }

    .a-img img{
        max-height: 15rem;
    }

    .about-img img{
        max-height: 15rem;
        max-width: 17rem;
    }

    .a-right{
        margin-left: 3rem;
        margin-top: 10.5rem;
        max-width: 80%;
    }

    .a-right h2{
        font-size: 1rem;
        line-height: 1.3rem;
    }

    .about-left h2{
        font-size: 1.5rem;
    }

    .about-divider{
        height: 1.3rem;
    }
    
}
