.testimonial{
    width: 100%;
    height: 133vh;
    background-color: var(--journeyColor);
}

.t-content{
    padding: 10rem 17rem;
    display: flex;
    flex-direction: column;
}

.t-first{
    display: flex;
    align-items: center;
    gap: 1.1rem;
}

.t-divider{
    border: 1px solid var(--primaryColor);
    height: 2.5rem;
}

.t-first h2{
    font-family: 'Poppins';
    text-transform: capitalize;
}

/* second part */

.t-second{
    width: 100%;
    display: flex;
    margin: 5rem 0;
    width: 100%;
    gap: 2rem;
    justify-content: space-evenly;
    margin-top: -5rem;
}

.t-card{
    width: 24.5rem;
    gap: 2rem;
    height: 35rem;
}

.t-img img{
    max-width: 100%;
    object-fit: cover;
    max-height: 17rem;
}

.tt-content{
    padding: 20px 40px;
}

.card-content h4{
    color: var(--textColor);
}

.card-content h5{
    padding: 2rem 0;
    font-weight: 400;
}

.t-learn{
    display: flex;
}

.t-social{
    display: flex;
    align-items: center;
    text-align: center;
    gap: 1rem;
    margin-left: 4rem;
}

.t-social a{
    color: var(--textColor);
}

.eye{
    flex-direction: column;
    display: flex;
}

.heart{
    flex-direction: column;
    display: flex;
}

.inbox{
    flex-direction: column;
    display: flex;
}

.share{
    flex-direction: column;
    display: flex;
}

.share a{
    color: var(--primaryColor);
}

.share span{
    color: transparent;
}

.t-social span{
    font-size: .7rem;
}

.t-card:hover{
    background-color: white;
    box-shadow: 1px 5px 20px rgba(0, 0, 0, 0.1);
}

.t-learn-btn button{
    font-size: .7rem;
}

.t-third{
    margin-top: 5rem;
}

.t-third button{
    display: block;
    margin: auto;
    font-size: 1.1rem;
    width: 12rem;
}

/* media queries */

@media screen and (max-width: 1440px) {

    .testimonial{
        height: 155vh;
    }
    .t-content{
        padding: 7rem 9rem;
    }

    .card-content{
        width: 100%;
    }

    .card-content h5{
        width: 100%;
    }

    .tt-content{
        padding: 30px 17px;
    }

    .t-learn{
        margin: 25px 0;
    }

    .t-third button{
        font-size: .8rem;
        width: 10rem;
    }
}

@media screen and (max-width: 856px) {

    .testimonial{
        height: 130vh;
    }
    .t-content{
        padding: 5rem 3.5rem;
        overflow: hidden;
    }

    .t-divider{
        height: 2rem;
    }

    .t-second{
        margin-top: -10rem;
    }

    .t-img img{
        max-height: 10rem;
    }

    .t-card{
        width: 14rem;
        gap: 1rem;
    }

    .t-third button{
        font-size: .8rem;
        width: 8rem;
        margin-top: -4rem;
    }

    .t-learn{
        flex-direction: column;
    }

    .t-learn-btn{
        text-align: center;
    }

    .t-social{
        margin-top: 2rem;
        margin-left: 2.5rem;
    }
}

@media screen and(max-width: 640px) {
    
.t-content{
    padding: 4rem 2.8rem;
}

.t-first h2{
    font-size: 1.5rem;
}

 .t-divider{
    height: 1rem;
 }

  .tt-content h4{
    font-size: .5rem;
 }
    .card-content h5{
        font-size: .7rem;
    }
}



